import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../../../gyzmo-commons/dao/db/base/db.dao.base";
import { NotImplementedError } from "../../../../../gyzmo-commons/helpers/notImplementedError";
import { AppSqlProvider } from "../../../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../../../gyzmo-commons/services/logs/logger.service";
import { Damage } from "../../../../models/inspection/v2/damage.model";
import { AttachmentDbDao } from "../../attachment.db.dao";

@Injectable({
    providedIn: "root",
})
export class DamageDbDaoV2 extends DbDaoBase<Damage> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider,
        private attachmentDbDao: AttachmentDbDao) {
        super(logger);
    }

    getByInspectionIdAndZoneId(inspectionId: string, zoneId: string, hydrate: boolean = false): Promise<Damage[]> {
        let selectQuery = "SELECT * FROM " + Damage.TABLENAME + " WHERE inspectionId = '" + inspectionId + "' AND zoneId = '" + zoneId + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let damages: Damage[] = [];
                for (const item of data.rows) {
                    let damage = this.rowToModel(item);
                    damages.push(damage);
                }

                return damages;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Damage.TABLENAME + "_inspectionId"
                + " ON " + Damage.TABLENAME + "(inspectionId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Damage.TABLENAME + "_zoneId"
                + " ON " + Damage.TABLENAME + "(zoneId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Damage.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "inspectionId TEXT, "
                    + "zoneId TEXT, "
                    + "elementId TEXT, "
                    + "value TEXT, "
                    + "clientResponsibility TEXT, "
                    + "xAxis NUMERIC, "
                    + "yAxis NUMERIC, "
                    + "rowNumber TEXT, "
                    + "updateDate TEXT, "
                    + "isOld NUMERIC,"
                    + "isDeactivate NUMERIC"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Damage.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Damage.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Damage> {
        throw new NotImplementedError();
    }

    public getTableName(): string {
        return Damage.TABLENAME;
    }

    protected rowToModel(row: any): Damage {
        let damage = new Damage();

        damage.id = row.id;
        damage.inspectionId = row.inspectionId;
        damage.zoneId = row.zoneId;
        damage.elementId = row.elementId;
        damage.value = row.value;
        damage.clientResponsibility = row.clientResponsibility;
        damage.xAxis = row.xAxis;
        damage.yAxis = row.yAxis;
        damage.rowNumber = row.rowNumber;
        damage.updateDate = row.updateDate;
        damage.isOld = row.isOld;
        damage.isDeactivate = row.isDeactivate;

        return damage;
    }

    public save(damage: Damage): Promise<Damage> {
        let promises = [];
        damage.attachments.forEach(attachment => {
            promises.push(this.attachmentDbDao.save(attachment));
        });

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Damage.TABLENAME + " ("
                            + "id, inspectionId, zoneId, elementId, value, clientResponsibility,"
                            + "xAxis, yAxis, rowNumber, updateDate, isOld, isDeactivate"
                            + ") VALUES ("
                            + this.getValue(damage.id)
                            + this.getValue(damage.inspectionId)
                            + this.getValue(damage.zoneId)
                            + this.getValue(damage.elementId)
                            + this.getValue(damage.value)
                            + this.getValue(damage.clientResponsibility)
                            + this.getValue(damage.xAxis)
                            + this.getValue(damage.yAxis)
                            + this.getValue(damage.rowNumber)
                            + this.getValue(damage.updateDate)
                            + this.getValue(damage.isOld)
                            + this.getValue(damage.isDeactivate, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return damage;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
