import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ActionSheetController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { LoadingHelper } from "../helpers/loading.helper";
import { PictureHelper } from "../helpers/picture.helper";
import { AttachmentFactory } from "../interfaces/attachmentFactory";
import { AttachmentKinds } from "../interfaces/attachmentKinds";
import { PICTURE_QUALITY, PICTURE_RESOLUTION } from "../interfaces/constants";
import { DateProvider } from "../interfaces/dateProvider";
import { IAttachmentDto } from "../interfaces/IAttachmentDto";
import { CameraService } from "../services/camera.service";
import { FileService } from "../services/file.service";
import { LoggerService } from "../services/logs/logger.service";
import { FormValidationBasePage } from "./formValidation.base.page";

@Component({
    template: "",
})
export abstract class FormValidationBasePageWithDocuments extends FormValidationBasePage {
    constructor(router: Router,
                route: ActivatedRoute,
                logger: LoggerService,
                protected loadingHelper: LoadingHelper,
                protected dateProvider: DateProvider,
                protected cameraService: CameraService,
                protected attachmentFactory: AttachmentFactory,
                protected actionSheetCtrl: ActionSheetController,
                protected translateService: TranslateService,
                protected pictureHelper: PictureHelper,
                protected fileService: FileService) {
        super(router, route, logger);
    }

    public async selectDocumentSource(attachmentsFormName: string, attachmentObject: string, attachmentKey: string, allowDocuments = true) {
        let buttons = [
            {
                icon: "camera-outline",
                text: this.translateService.instant("IONIC_Camera"),
                data: {
                    action: "camera",
                },
                handler: () => {
                    this.addPicture(attachmentsFormName, attachmentObject, attachmentKey);
                },
            },
            {
                icon: "image-outline",
                text: this.translateService.instant("IONIC_Gallery"),
                data: {
                    action: "gallery",
                },
                handler: () => {
                    this.addPictureFromGallery(attachmentsFormName, attachmentObject, attachmentKey);
                },
            }];

        if (allowDocuments) {
            buttons.push({
                icon: "document-outline",
                text: this.translateService.instant("IONIC_Document"),
                data: {
                    action: "document",
                },
                handler: () => {
                    this.addDocument(attachmentsFormName, attachmentObject, attachmentKey);
                },
            });
        }

        const actionSheet = await this.actionSheetCtrl.create({
            header: this.translateService.instant("IONIC_Select_source"),
            buttons: buttons,
        });
        await actionSheet.present();
    }

    private addDocument(attachmentsFormName: string, attachmentObject: string, attachmentKey: string) {
        this.fileService.pickAndReadDocument()
            .then(async (content) => {
                // Si on est dans le browser et qu'on fait un "back" sur la prise de photo, data64 undefined
                if (content) {
                    await this.loadingHelper.showLoading();
                    let attachment: IAttachmentDto = this.attachmentFactory.createAttachment();
                    attachment.modified = true;
                    attachment.object = attachmentObject;
                    attachment.key = attachmentKey;
                    attachment.date = this.dateProvider.now();
                    attachment.attachmentKind.id = AttachmentKinds.FICHIERS;
                    attachment.file = content;
                    this.getFormControl(attachmentsFormName).value.push(attachment);
                    await this.loadingHelper.hideLoading();
                }
            })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
            });
    }

    private addPicture(attachmentsFormName: string, attachmentObject: string, attachmentKey: string) {
        this.cameraService.getPicture(null, null, PICTURE_RESOLUTION, 85)
            .then(async (imageData) => {
                if (imageData) {
                    await this.loadingHelper.showLoading();
                    let attachment: IAttachmentDto = this.attachmentFactory.createAttachment();
                    attachment.modified = true;
                    attachment.object = attachmentObject;
                    attachment.key = attachmentKey;
                    attachment.date = this.dateProvider.now();
                    attachment.attachmentKind.id = AttachmentKinds.PHOTO;
                    attachment.file = await this.pictureHelper.watermarkPicture(imageData, PICTURE_QUALITY);
                    this.getFormControl(attachmentsFormName).value.push(attachment);
                    await this.loadingHelper.hideLoading();
                }
            })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
            });
    }

    public editPicture(attachmentsFormName: string, attachment: IAttachmentDto) {
        void this.pictureHelper.viewImage(attachment.file, true)
            .then((result: string) => {
                if (result) {
                    let attachment = this.getFormControl(attachmentsFormName).value.find((element: IAttachmentDto) => {
                        return element.date == attachment.date
                               && element.file === attachment.file;
                    });

                    attachment.file = result;
                }
            });
    }

    public deleteDocument(attachmentsFormName: string, attachment: IAttachmentDto) {
        if (!this.getFormControl(attachmentsFormName).value || this.getFormControl(attachmentsFormName).value.length <= 0) {
            return;
        }

        this.getFormControl(attachmentsFormName).setValue(this.getFormControl(attachmentsFormName).value.filter((object: IAttachmentDto) => {
            return object != attachment;
        }));
    }

    private addPictureFromGallery(attachmentsFormName: string, attachmentObject: string, attachmentKey: string) {
        this.cameraService.getPictureFromGallery()
            .then(async (imageData) => {
                // Si on est dans le browser et qu'on fait un "back" sur la prise de photo, data64 undefined
                if (imageData) {
                    await this.loadingHelper.showLoading();
                    let attachment: IAttachmentDto = this.attachmentFactory.createAttachment();
                    attachment.modified = true;
                    attachment.object = attachmentObject;
                    attachment.key = attachmentKey;
                    attachment.date = this.dateProvider.now();
                    attachment.attachmentKind.id = AttachmentKinds.PHOTO;
                    attachment.file = await this.pictureHelper.watermarkPicture(imageData, PICTURE_QUALITY);
                    this.getFormControl(attachmentsFormName).value.push(attachment);
                    await this.loadingHelper.hideLoading();
                }
            })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
            });
    }
}
