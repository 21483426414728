import { DateTime } from "luxon";
import { BaseDto } from "../../gyzmo-commons/dtos/base/base.dto";
import { IdWordingDto } from "../../gyzmo-commons/dtos/idWording.dto";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { IAttachmentDto } from "../../gyzmo-commons/interfaces/IAttachmentDto";
import { Attachment } from "../models/attachment.model";
import { AttachedDocumentDto } from "./attachedDocument.dto";

export class AttachmentDto extends BaseDto<Attachment> implements IAttachmentDto {
    public id: string = "";
    public wording: string = "";
    public date: DateTime = null;
    public file: string = "";
    public attachedDocuments: AttachedDocumentDto[] = [];
    public txt: string = "";
    public object: string = "";
    public key: string = "";
    public attachmentKind: IdWordingDto = null;

    // volatile
    public modified: boolean = false;

    constructor() {
        super();
        this.attachmentKind = new IdWordingDto();
    }

    public static fromBody(body: any, object: string, key: string): AttachmentDto {
        let attachmentDto = new AttachmentDto();

        attachmentDto.id = "" + body.id;
        attachmentDto.date = DateHelper.tryFromFormat(body.date, DATE_NODEJS_FORMAT);
        attachmentDto.wording = body.wording;
        attachmentDto.txt = body.txt;
        attachmentDto.modified = false;
        attachmentDto.object = object;
        attachmentDto.key = key;

        if (body.attachmentKind) {
            attachmentDto.attachmentKind = IdWordingDto.fromBody(body.attachmentKind);
        }

        if (body.file) {
            attachmentDto.file = body.file;
        }

        if (body.attachedDocuments) {
            body.attachedDocuments.forEach(attachedDocument => {
                attachmentDto.attachedDocuments.push(AttachedDocumentDto.fromBody(attachedDocument));
            });
        }

        return attachmentDto;
    }

    public static fromModel(model: Attachment): AttachmentDto {
        let attachmentDto = new AttachmentDto();

        attachmentDto.id = model.id;
        attachmentDto.wording = model.wording;
        attachmentDto.attachmentKind = IdWordingDto.fromModel(model.attachmentKind);
        attachmentDto.date = DateHelper.tryFromISO(model.date);
        attachmentDto.file = model.file;
        attachmentDto.attachedDocuments = AttachedDocumentDto.fromModels(model.attachedDocuments);
        attachmentDto.txt = model.txt;
        attachmentDto.object = model.object;
        attachmentDto.key = model.key;
        attachmentDto.modified = (model.modified == 1);

        return attachmentDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        delete clone.modified;
        delete clone.object;

        clone.date = DateHelper.tryToFormat(this.date, DATE_NODEJS_FORMAT);

        let array = [];
        this.attachedDocuments.forEach(document => {
            array.push(document.toBody());
        });
        clone.attachedDocuments = array;

        return clone;
    }

    public toModel(): Attachment {
        let attachment = new Attachment();

        attachment.id = this.id;
        attachment.date = DateHelper.tryToISO(this.date);
        attachment.wording = this.wording;
        attachment.txt = this.txt;
        attachment.attachmentKind = this.attachmentKind.toModel();
        attachment.file = this.file;
        attachment.modified = 0;
        attachment.object = this.object;
        attachment.key = this.key;

        this.attachedDocuments.forEach(attachedDocument => {
            attachment.attachedDocuments.push(attachedDocument.toModel());
        });

        return attachment;
    }

    public clone(): AttachmentDto {
        let clone = new AttachmentDto();

        clone.id = this.id;
        clone.date = this.date;
        clone.wording = this.wording;
        clone.txt = this.txt;
        clone.attachmentKind = this.attachmentKind.clone();
        clone.file = this.file;
        clone.modified = this.modified;
        clone.object = this.object;
        clone.key = this.key;

        this.attachedDocuments.forEach(attachedDocument => {
            clone.attachedDocuments.push(attachedDocument.clone());
        });

        return clone;
    }
}
