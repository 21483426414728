import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { isNullOrUndefined } from "../../gyzmo-commons/helpers/null.helper";
import { IUserLoginService } from "../../gyzmo-commons/interfaces/IUserLoginService";
import { AppSqlProvider } from "../../gyzmo-commons/persistence/app.sql.provider";
import { Database } from "../../gyzmo-commons/persistence/database";
import { CacheService } from "../../gyzmo-commons/services/cache.service";
import { UserDbDao } from "../dao/db/user.db.dao";
import { UserWsDao } from "../dao/ws/user.ws.dao";
import { UserDto } from "../dto/user.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";
import { InspectionServiceV2 } from "./inspection/v2/inspection.service";

@Injectable({
    providedIn: "root",
})
export class UserService extends IUserLoginService {
    constructor(private database: Database,
                private appSqlProvider: AppSqlProvider,
                private cacheService: CacheService,
                private serversConnectionsProvider: ServersConnectionsProvider,
                private userWsDao: UserWsDao,
                private userDbDao: UserDbDao,
                private inspectionServiceV2: InspectionServiceV2) {
        super();
    }

    public isConnected(): Promise<boolean> {
        return this.getCurrentUser(false)
            .then((user: UserDto) => {
                return !isNullOrUndefined(user);
            });
    }

    public getCurrentUser(hydrateUser: boolean): Promise<UserDto> {
        return this.userDbDao.get(null, hydrateUser)
            .then(user => {
                if (user) {
                    return UserDto.fromModel(user);
                } else {
                    return null;
                }
            });
    }

    public async connect(login: string, password: string): Promise<UserDto> {
        await this.disconnect(false);

        let result = await this.userWsDao.connect(this.serversConnectionsProvider.getServerConnection(), login, password);
        await this.userDbDao.save(result.user.toModel());
        await this.serversConnectionsProvider.getServerConnection().setAuthentication(result.token);

        return result.user;
    }

    public async disconnect(remoteLogout: boolean): Promise<boolean> {

        let connectedUser = await this.userDbDao.getCurrentUserWithThirdPartyOnly();
        await this.userWsDao.disconnect(this.serversConnectionsProvider.getServerConnection(), remoteLogout);

        await this.database.cleanOnDisconnection();
        await this.cacheService.cleanCache();

        if (environment.mocked) {
            this.inspectionServiceV2.clearMockCache();
        }

        // In case the bulk mode was active
        await this.appSqlProvider.commitBulk();

        return connectedUser != null;
    }

    public getCurrentUserWithThirdPartyOnly(): Promise<UserDto> {
        return this.userDbDao.getCurrentUserWithThirdPartyOnly()
            .then(user => {
                if (user) {
                    return UserDto.fromModel(user);
                } else {
                    return null;
                }
            });
    }

    public lostPassword(username: string): Promise<void> {
        return this.userWsDao.lostPassword(this.serversConnectionsProvider.getServerConnection(), username);
    }

    public updatePassword(actualPassword: string, newPassword: string): Promise<void> {
        return this.userWsDao.updatePassword(this.serversConnectionsProvider.getServerConnection(), actualPassword, newPassword);
    }

    public async save(user: UserDto): Promise<UserDto> {
        let updatedDto = await this.userWsDao.save(this.serversConnectionsProvider.getServerConnection(), user);

        await this.userDbDao.save(updatedDto.toModel());
        return updatedDto;
    }

    public async checkToken(): Promise<boolean> {
        let result = true;
        let value = await this.userWsDao.checkToken(this.serversConnectionsProvider.getServerConnection());
        if (!value) {
            await this.disconnect(true);
            result = false;
        }

        return result;
    }
}
