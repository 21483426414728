import { v4 as uuidv4 } from "uuid";

export class NumberHelper {
    public static replaceDotComma(value: number): number {
        return parseFloat(value.toString().replace(".", "").replace(",", ""));
    }

    public static generateGUID() {
        return uuidv4();
    }
}
