import { DateTime } from "luxon";
import { BaseDto } from "../../../../gyzmo-commons/dtos/base/base.dto";
import { IdWordingDto } from "../../../../gyzmo-commons/dtos/idWording.dto";
import { DateHelper } from "../../../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../../../gyzmo-commons/interfaces/constants";
import { INSPECTION_KINDS } from "../../../interfaces/INSPECTION_KINDS";
import { Inspection } from "../../../models/inspection/v2/inspection.model";
import { AttachmentDto } from "../../attachment.dto";
import { EquipmentDto } from "../../equipment.dto";
import { MovementDto } from "../../movement.dto";
import { ServerDto } from "../../server.dto";
import { VehiclePartDto } from "../../wear/vehiclePart.dto";
import { InspectionDtoCommonInterface } from "../common/inspectionDtoCommonInterface";
import { ChecklistDto } from "./checklist.dto";
import { FaceDto } from "./face.dto";

export class InspectionDto extends BaseDto<Inspection> implements InspectionDtoCommonInterface {
    public updateDate: DateTime = null;
    public visitDate: DateTime = null;
    public agentName: string = "";
    public driverMail: string = "";
    public linkedObject: string = "";
    public inspectionModel: IdWordingDto = null;
    public user: { id?: string };
    public attachments: AttachmentDto[] = [];
    public checklists: ChecklistDto[] = [];
    public driverName: string = "";
    public equipment: EquipmentDto;
    public faces: FaceDto[] = [];
    public id: string = "";
    public kind: INSPECTION_KINDS;
    public movement: MovementDto;
    public vehicleParts: VehiclePartDto[] = [];

    constructor() {
        super();

        this.inspectionModel = new IdWordingDto();
        this.user = {};
        this.equipment = new EquipmentDto();
        this.movement = new MovementDto();
    }

    static fromBody(body: any, serverDto: ServerDto): InspectionDto {
        let inspectionDto = new InspectionDto();

        inspectionDto.id = "" + body.id;
        inspectionDto.updateDate = DateHelper.tryFromFormat(body.updateDate, DATE_NODEJS_FORMAT);
        inspectionDto.visitDate = DateHelper.tryFromFormat(body.visitDate, DATE_NODEJS_FORMAT);
        inspectionDto.linkedObject = body.linkedObject;
        inspectionDto.agentName = body.agentName;
        inspectionDto.driverName = body.driverName;
        inspectionDto.driverMail = body.driverMail;

        if (body.checklists) {
            body.checklists.forEach((checklist) => {
                inspectionDto.checklists.push(ChecklistDto.fromBody(checklist, inspectionDto.id));
            });
        }

        if (body.faces) {
            body.faces.forEach((face) => {
                inspectionDto.faces.push(FaceDto.fromBody(face, inspectionDto.id));
            });
        }

        if (body.inspectionModel) {
            inspectionDto.inspectionModel = IdWordingDto.fromBody(body.inspectionModel);
        }

        if (body.user) {
            inspectionDto.user.id = body.user.id;
        }

        if (body.equipment) {
            inspectionDto.equipment = EquipmentDto.fromBody(body.equipment);
        }

        if (body.movement) {
            inspectionDto.movement = MovementDto.fromBody(body.movement, serverDto);
        }

        if (body.kind) {
            inspectionDto.kind = body.kind.id;
        }

        if (body.attachments) {
            body.attachments.forEach(attachment => {
                inspectionDto.attachments.push(AttachmentDto.fromBody(attachment, "inspection", inspectionDto.id));
            });
        }

        return inspectionDto;
    }

    static fromModel(inspection: Inspection): InspectionDto {
        let inspectionDto = new InspectionDto();

        inspectionDto.id = inspection.id;
        inspectionDto.updateDate = DateHelper.tryFromISO(inspection.updateDate);
        inspectionDto.visitDate = DateHelper.tryFromISO(inspection.visitDate);
        inspectionDto.linkedObject = inspection.linkedObject;

        inspectionDto.agentName = inspection.agentName;
        inspectionDto.driverName = inspection.driverName;
        inspectionDto.driverMail = inspection.driverMail;

        inspectionDto.user = inspection.user;

        inspectionDto.kind = inspection.kind;

        if (inspection.inspectionModel) {
            inspectionDto.inspectionModel = IdWordingDto.fromModel(inspection.inspectionModel);
        }

        if (inspection.equipment) {
            inspectionDto.equipment = EquipmentDto.fromModel(inspection.equipment);
        }

        if (inspection.movement?.id) {
            inspectionDto.movement = MovementDto.fromModel(inspection.movement);
        }

        if (inspection.checklists) {
            inspection.checklists.forEach((checklist) => {
                inspectionDto.checklists.push(ChecklistDto.fromModel(checklist));
            });
        }

        if (inspection.faces) {
            inspection.faces.forEach((face) => {
                inspectionDto.faces.push(FaceDto.fromModel(face));
            });
        }

        if (inspection.attachments) {
            inspection.attachments.forEach(attachment => {
                inspectionDto.attachments.push(AttachmentDto.fromModel(attachment));
            });
        }

        return inspectionDto;
    }

    public isOnDay(activeDay: DateTime): boolean {
        return DateHelper.isSameDay(this.visitDate, activeDay);
    }

    public toBody() {
        const clone: any = { ...this };

        // Pour verifier que clone.mileage2 est gere par le back regarder : V8004-1576

        clone.kind = { id: clone.kind };
        clone.groupingCode = clone.id;
        clone.updateDate = DateHelper.tryToFormat(this.updateDate, DATE_NODEJS_FORMAT);
        clone.visitDate = DateHelper.tryToFormat(this.updateDate, DATE_NODEJS_FORMAT);
        clone.equipment = this.equipment.toBody();
        clone.inspectionModel = this.inspectionModel.toBody();

        if (this.kind == INSPECTION_KINDS.INTERMEDIATE) {
            delete clone.movement;
        } else {
            clone.movement = this.movement.toBody();
            clone.movement.customer = { id: clone.movement.customer.id };
            clone.movement.driver = { id: clone.movement.driver.id };
            clone.movement.startLocation = { id: clone.movement.startLocation.id };
            clone.movement.returnLocation = { id: clone.movement.returnLocation.id };
            clone.movement.equipment = { "id": this.equipment.id };
        }

        clone.checklists = [];
        this.checklists.forEach(checklist => {
            checklist.elements.forEach(element => {
                delete element.checklistId;
                delete element.inspectionId;
            });
            clone.checklists.push(checklist.toBody());
        });

        clone.faces = [];
        this.faces.forEach(face => {
            face.zones.forEach(zone => {
                delete zone.faceId;
                zone.damages.forEach(damage => {
                    delete damage.inspectionId;
                    delete damage.zoneId;
                });
            });
            clone.faces.push(face.toBody());
        });

        clone.attachments = [];
        this.attachments.forEach(attachment => {
            clone.attachments.push(attachment.toBody());
        });

        // Nettoyer l'objet avant envoi
        delete clone.groupingCode;
        delete clone.vehicleParts; // C'est un appel à part

        return clone;
    }

    public toModel(): Inspection {
        let inspection = new Inspection();

        inspection.id = this.id;
        inspection.updateDate = DateHelper.tryToISO(this.updateDate);
        inspection.visitDate = DateHelper.tryToISO(this.visitDate);
        inspection.agentName = this.agentName;
        inspection.driverName = this.driverName;
        inspection.driverMail = this.driverMail;
        inspection.linkedObject = this.linkedObject;
        inspection.driverMail = this.driverMail;
        inspection.kind = this.kind;
        inspection.user = this.user;

        if (this.inspectionModel) {
            inspection.inspectionModel = this.inspectionModel.toModel();
        }

        if (this.equipment) {
            inspection.equipment = this.equipment.toModel();
        }

        if (this.movement) {
            inspection.movement = this.movement.toModel();
        }

        inspection.checklistsIds = "";
        if (this.checklists) {
            this.checklists.forEach((checklist) => {
                inspection.checklists.push(checklist.toModel());
                inspection.checklistsIds += checklist.id + ";";
            });
        }

        inspection.facesIds = "";
        if (this.faces) {
            this.faces.forEach((face) => {
                inspection.faces.push(face.toModel());
                inspection.facesIds += face.id + ";";
            });
        }

        if (this.attachments) {
            this.attachments.forEach(attachment => {
                inspection.attachments.push(attachment.toModel());
            });
        }

        return inspection;
    }
}
