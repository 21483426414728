export class ThreadHelper {
    public static sleep(milliseconds: number) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                resolve();
            }, milliseconds);
        });

    }

    public static start(target: () => void) {
        setTimeout(target, 0);
    }
}
