import { IUserDto } from "./IUserDto";

export abstract class IUserLoginService {
    abstract getCurrentUserWithThirdPartyOnly(): Promise<IUserDto>;

    abstract connect(login: string, password: string): Promise<IUserDto>;

    abstract disconnect(remoteDisconnect: boolean): Promise<boolean>;

    abstract updatePassword(currentPassword: string, newPassword: string): Promise<void>;

    abstract lostPassword(formValue: string): Promise<void>;
}
