import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../../../gyzmo-commons/dao/db/base/db.dao.base";
import { NotImplementedError } from "../../../../../gyzmo-commons/helpers/notImplementedError";
import { AppSqlProvider } from "../../../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../../../gyzmo-commons/services/logs/logger.service";
import { ChecklistElement } from "../../../../models/inspection/v2/checklistElement.model";
import { AttachmentDbDao } from "../../attachment.db.dao";

@Injectable({
    providedIn: "root",
})
export class ChecklistElementDbDaoV2 extends DbDaoBase<ChecklistElement> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider,
                private attachmentDbDao: AttachmentDbDao) {
        super(logger);
    }

    getByInspectionIdAndChecklistId(inspectionId: string, checklistId: string, hydrate: boolean = false): Promise<ChecklistElement[]> {
        let selectQuery = "SELECT * FROM " + ChecklistElement.TABLENAME + " WHERE inspectionId = '" + inspectionId + "' AND checklistId = '" + checklistId + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let checklists: ChecklistElement[] = [];
                for (const item of data.rows) {
                    let checklistElement = this.rowToModel(item);
                    checklists.push(checklistElement);
                }

                let hydratationPromises = [];

                if (hydrate) {
                    checklists.forEach(checklistElement => {
                        hydratationPromises.push(this.attachmentDbDao.getByObjectAndKey("inspection", checklistElement.id)
                            .then(value => {
                                checklistElement.attachments = value;
                            }));
                    });
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return checklists;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + ChecklistElement.TABLENAME + "_checklistId"
                + " ON " + ChecklistElement.TABLENAME + "(checklistId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + ChecklistElement.TABLENAME + "_inspectionId"
                + " ON " + ChecklistElement.TABLENAME + "(inspectionId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + ChecklistElement.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "inspectionId TEXT, "
                    + "checklistId TEXT, "
                    + "wording TEXT, "
                    + "defaultValue TEXT, "
                    + "savedValue TEXT, "
                    + "param TEXT, "
                    + "checklistModelId TEXT, "
                    + "technicalLink TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(checklistId: string): Promise<any> {
        let selectQuery = "DELETE FROM " + ChecklistElement.TABLENAME + " WHERE checklistId='" + checklistId + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + ChecklistElement.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<ChecklistElement> {
        throw new NotImplementedError();
    }

    public getTableName(): string {
        return ChecklistElement.TABLENAME;
    }

    protected rowToModel(row: any): ChecklistElement {
        let checklistElement = new ChecklistElement();

        checklistElement.id = row.id;
        checklistElement.inspectionId = row.inspectionId;
        checklistElement.checklistId = row.checklistId;
        checklistElement.wording = row.wording;
        checklistElement.defaultValue = row.defaultValue;
        checklistElement.savedValue = row.savedValue;
        checklistElement.param = row.param;
        checklistElement.checklistModelId = row.checklistModelId;
        checklistElement.technicalLink = row.technicalLink;

        return checklistElement;
    }

    public save(checklistElement: ChecklistElement): Promise<ChecklistElement> {
        let promises = [];

        checklistElement.attachments.forEach(attachment => {
            promises.push(this.attachmentDbDao.save(attachment));
        });

        return Promise.all(promises).then(value => {
            let query = "INSERT OR REPLACE INTO " + ChecklistElement.TABLENAME + " "
                        + "(id, inspectionId, checklistId, wording, defaultValue, savedValue, param, checklistModelId, technicalLink) VALUES ("
                        + this.getValue(checklistElement.id)
                        + this.getValue(checklistElement.inspectionId)
                        + this.getValue(checklistElement.checklistId)
                        + this.getValue(checklistElement.wording)
                        + this.getValue(checklistElement.defaultValue)
                        + this.getValue(checklistElement.savedValue)
                        + this.getValue(checklistElement.param)
                        + this.getValue(checklistElement.checklistModelId)
                        + this.getValue(checklistElement.technicalLink, true)
                        + ");";

            return this.sqlProvider.query(query)
                .then(response => {
                    return checklistElement;
                })
                .catch(reason => {
                    this.logSqlError(reason);
                    return null;
                });
        });
    }
}
