import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DbDaoBase } from "../dao/db/base/db.dao.base";
import { VersionDbDao } from "../dao/db/version.db.dao";
import { VersionDto } from "../dtos/version.dto";
import { AssetsHelper } from "../helpers/assets.helper";
import { LoadingHelper } from "../helpers/loading.helper";
import { SqlHelper } from "../helpers/sql.helper";
import { CacheService } from "../services/cache.service";
import { LoggerService } from "../services/logs/logger.service";
import { AppSqlProvider } from "./app.sql.provider";

@Injectable({
    providedIn: "root",
})
export class Database {
    private allDbDao: DbDaoBase<any>[];
    private onDisconnectDbDao: DbDaoBase<any>[];

    constructor(private logger: LoggerService,
                private loadingHelper: LoadingHelper,
                private translateService: TranslateService,
                private assetsHelper: AssetsHelper,
                private versionDbDao: VersionDbDao,
                private cacheService: CacheService,
                private sqlProvider: AppSqlProvider) {
    }

    public async initialize(allDbDao: DbDaoBase<any>[],
                            onDisconnectDbDao: DbDaoBase<any>[],
                            currentAppVersion: number,
                            currentDbVersion: number) {
        this.logger.info(this.constructor.name, "initialize");

        this.allDbDao = allDbDao;
        this.onDisconnectDbDao = onDisconnectDbDao;

        await this.createTablesIfNeeded();

        if (currentDbVersion == 0) {
            this.logger.info(this.constructor.name, "No need to update structure.");
        } else if (currentAppVersion > currentDbVersion) {
            await this.updateStructureIfNeeded(currentAppVersion, currentDbVersion);
            await this.cacheService.cleanCache(true);

            this.logger.info(this.constructor.name, "Update structure done.");
        }

        await this.versionDbDao.saveCurrentVersion(new VersionDto(0, "" + currentAppVersion).toModel());
    }

    public getExistingTables(): Promise<string[]> {
        return this.sqlProvider.getExistingTables();
    }

    public async dropTables(): Promise<any> {
        this.logger.info(this.constructor.name, "dropTables");

        let tables = await this.getExistingTables();
        for (const table of tables) {
            await this.sqlProvider.query(`DROP TABLE ${ table }`);
        }
    }

    public cleanOnDisconnection(): Promise<any> {
        const promises = [];

        if (!this.onDisconnectDbDao || this.onDisconnectDbDao.length <= 0) {
            this.logger.warn(this.constructor.name, "onDisconnectDbDao is empty ! Did you call initialize ? Or you have database imported in two modules for injection.");
        }

        this.onDisconnectDbDao.forEach(dbDao => {
            promises.push(dbDao.deleteAll());
        });

        return Promise.all(promises);
    }

    protected logSqlError(error: any) {
        this.logger.error(this.constructor.name, SqlHelper.stringifySqlError(error));
    }

    private async createTablesIfNeeded(): Promise<any> {
        const promises = [];
        let tables = await this.getExistingTables();

        for (const dbDao of this.allDbDao) {
            if (tables.indexOf(dbDao.getTableName()) < 0) {
                this.logger.info(this.constructor.name, "need to create table " + dbDao.getTableName());
                promises.push(dbDao.createTable());
            }
        }

        return Promise.all(promises);
    }

    private async updateStructureIfNeeded(currentAppVersion: number, currentDbVersion: number) {
        this.logger.info(this.constructor.name, "Update structure needed; From " + currentDbVersion + " to " + currentAppVersion);
        void this.loadingHelper.showLoading(this.translateService.instant("IONIC_Updating_the_database..."));

        // Upgrade tables
        let databaseUpgradesIndex = await this.assetsHelper.readFile("databaseUpgrades/list.json");

        let databaseUpgradeVersions: { versionCode: number; file: string }[] = [];
        for (const databaseUpgradeFilename of databaseUpgradesIndex) {
            let fileVersion = Number(("" + databaseUpgradeFilename).replace(".sql", ""));
            databaseUpgradeVersions.push({ versionCode: fileVersion, file: databaseUpgradeFilename });
        }

        databaseUpgradeVersions = databaseUpgradeVersions.sort((a, b) => {
            // Sort ascending
            return a.versionCode - b.versionCode;
        });

        for (const databaseUpgradeVersion of databaseUpgradeVersions) {
            if (databaseUpgradeVersion.versionCode > currentDbVersion) {
                this.logger.info(this.constructor.name, "Applying upgrade from " + databaseUpgradeVersion.file);

                let sqlFileContent = await this.assetsHelper.readFileAsText("databaseUpgrades/" + databaseUpgradeVersion.file)
                    .catch(reason => {
                        this.logger.error(this.constructor.name, reason);
                        return "";
                    });

                await SqlHelper.runSqlFile(this.sqlProvider, sqlFileContent, this.logger)
                    .catch(reason => {
                        this.logSqlError(reason);
                    });
            }
        }

        await this.loadingHelper.hideAll();
    }
}
