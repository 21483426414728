import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Registration } from "../../models/registration.model";

@Injectable({
    providedIn: "root",
})
export class RegistrationDbDao extends DbDaoBase<Registration> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Registration.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "date TEXT, "
                    + "registration TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Registration.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Registration.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Registration> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Registration.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Registration.TABLENAME;
    }

    protected rowToModel(row: any): Registration {
        let registration = new Registration();
        registration.id = row.id;
        registration.date = row.date;
        registration.registration = row.registration;

        return registration;
    }

    public save(registration: Registration): Promise<Registration> {
        let query = "INSERT OR REPLACE INTO " + Registration.TABLENAME + " (id, date, registration) VALUES ("
                    + this.getValue(registration.id)
                    + this.getValue(registration.date)
                    + this.getValue(registration.registration, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return registration;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
