import { DateTime } from "luxon";
import { DateHelper } from "../helpers/date.helper";
import { ModelBase } from "./base/model.base";

export class CachedValue extends ModelBase {
    static readonly TABLENAME = "CachedValues";

    public key: string = "";
    public value: string = "";
    public date: DateTime = null;
    public expirationDate: DateTime = null;

    constructor(key, value, date: DateTime, expirationDate: DateTime) {
        super(CachedValue.TABLENAME);

        this.key = key;
        this.value = value;
        this.date = date;
        this.expirationDate = expirationDate;
    }

    public static fromRow(row: any): CachedValue {
        return new CachedValue(row.key,
            row.value,
            DateHelper.tryFromISO(row.date),
            DateHelper.tryFromISO(row.expirationDate));
    }
}
