import { IServerConnection } from "./IServerConnection";
import { IServerDto } from "./IServerDto";

export abstract class IServerDefaultConnectionProvider {
    abstract setServerConnection(url: string, apikey: string, context: string): Promise<IServerDto>;

    abstract getServerConnection(): IServerConnection ;

    abstract getPrimaryServerConnection(): IServerConnection;

    abstract createServerDto(): IServerDto;
}
