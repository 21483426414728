import { Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { PictureHelper } from "../../helpers/picture.helper";
import { AttachmentKinds } from "../../interfaces/attachmentKinds";
import { IAttachmentDto } from "../../interfaces/IAttachmentDto";
import { FileService } from "../../services/file.service";

@Component({
    selector: "irium-gallery",
    templateUrl: "irium-gallery.html",
    styleUrls: ["irium-gallery.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => IriumGallery),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => IriumGallery),
        },
    ],
})
export class IriumGallery implements ControlValueAccessor, Validator {
    @Input() disabled: boolean = false;
    @Input() showDocuments: boolean = false;

    @Input() object: string = "";
    @Input() key: string = "";

    @Output() onEditPhotoAttachment: EventEmitter<IAttachmentDto> = new EventEmitter();
    @Output() onDeleteAttachment: EventEmitter<IAttachmentDto> = new EventEmitter();

    value: IAttachmentDto[];
    touched = false;
    selectionIndex = -1;

    constructor(private pictureHelper: PictureHelper,
                private fileService: FileService) {
    }

    public deselectDocument($event) {
        this.selectionIndex = -1;
    }

    public showDocument(attachment: IAttachmentDto) {
        if (attachment.attachmentKind.id == AttachmentKinds.PHOTO) {
            void this.pictureHelper.viewImage(attachment.file, false);
        } else {
            void this.fileService.openDocument(attachment.file);
        }
    }

    public getAttachments(): IAttachmentDto[] {
        if (!this.showDocuments) {
            return this.value.filter(value => {
                return value.attachmentKind.id == AttachmentKinds.PHOTO;
            });
        } else {
            return this.value;
        }
    }

    onChange = (value) => {
    };

    onTouched = () => {
    };

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    writeValue(value: any) {
        this.value = value;
    }

    markAsTouched() {
        this.onTouched();
        this.touched = true;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null;
    }

    public selectDocument($event, index: number) {
        $event.stopPropagation();

        if (this.selectionIndex != index) {
            this.selectionIndex = index;
        } else {
            this.selectionIndex = -1;
        }
    }
}
