// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  contain: content;
}

.wrapper {
  margin-left: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding-bottom: 5px;
}

.bullet {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 10px;
}

.with-bottom-border {
  border-bottom: var(--with-bottom-border-color) thin solid;
}

.date {
  padding: 7px;
}

.green {
  background: var(--ion-color-green);
  color: var(--ion-color-green-contrast);
}

.yellow {
  background: var(--ion-color-yellow);
  color: var(--ion-color-yellow-contrast);
}

.orange {
  background: var(--ion-color-orange);
  color: var(--ion-color-orange-contrast);
}

.red {
  background: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.line {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/maintenance-control/maintenance-control.component.scss","webpack://./src/app/components/maintenance-control/maintenance-control-help/maintenance-control-help.popover.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;ACCJ;;ADEA;EACI,iBAAA;EACA,aAAA;EACA,oCAAA;EACA,mBAAA;EACA,mBAAA;ACCJ;;ADEA;EACI,qBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;ACCJ;;ADEA;EACI,yDAAA;ACCJ;;ADEA;EACI,YAAA;ACCJ;;ADEA;EACI,kCAAA;EACA,sCAAA;ACCJ;;ADEA;EACI,mCAAA;EACA,uCAAA;ACCJ;;ADEA;EACI,mCAAA;EACA,uCAAA;ACCJ;;ADEA;EACI,mCAAA;EACA,uCAAA;ACCJ;;AA5CA;EACI,aAAA;EACA,+BAAA;EACA,mBAAA;EACA,kBAAA;AA+CJ","sourcesContent":[":host {\n    contain: content;\n}\n\n.wrapper {\n    margin-left: 10px;\n    display: grid;\n    grid-template-columns: auto 1fr auto;\n    align-items: center;\n    padding-bottom: 5px;\n}\n\n.bullet {\n    display: inline-block;\n    width: 20px;\n    height: 20px;\n    border-radius: 20px;\n    margin-right: 10px;\n}\n\n.with-bottom-border {\n    border-bottom: var(--with-bottom-border-color) thin solid;\n}\n\n.date {\n    padding: 7px;\n}\n\n.green {\n    background: var(--ion-color-green);\n    color: var(--ion-color-green-contrast);\n}\n\n.yellow {\n    background: var(--ion-color-yellow);\n    color: var(--ion-color-yellow-contrast);\n}\n\n.orange {\n    background: var(--ion-color-orange);\n    color: var(--ion-color-orange-contrast);\n}\n\n.red {\n    background: var(--ion-color-danger);\n    color: var(--ion-color-danger-contrast);\n}\n","@use '../maintenance-control.component';\n\n.line {\n    display: grid;\n    grid-template-columns: auto 1fr;\n    align-items: center;\n    margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
