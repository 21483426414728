import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class Registration extends ModelBase {
    static readonly TABLENAME = "Registrations";

    public id: string = "";
    public date: string = "";
    public registration: string = "";

    constructor() {
        super(Registration.TABLENAME);
    }
}

