import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../../../gyzmo-commons/dao/db/base/db.dao.base";
import { NotImplementedError } from "../../../../../gyzmo-commons/helpers/notImplementedError";
import { AppSqlProvider } from "../../../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../../../gyzmo-commons/services/logs/logger.service";
import { Zone } from "../../../../models/inspection/v2/zone.model";
import { DamageDbDaoV2 } from "./damage.db.dao";

@Injectable({
    providedIn: "root",
})
export class ZoneDbDaoV2 extends DbDaoBase<Zone> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider,
                private damageDbDao: DamageDbDaoV2) {
        super(logger);
    }

    getByFaceId(inspectionId: string, faceId: string, hydrate: boolean = false): Promise<Zone[]> {
        let selectQuery = "SELECT * FROM " + Zone.TABLENAME + " WHERE faceId = '" + faceId + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let zones: Zone[] = [];
                for (const item of data.rows) {
                    let zone = this.rowToModel(item);
                    zones.push(zone);
                }

                let hydratationPromises = [];
                zones.forEach(zone => {
                    hydratationPromises.push(this.damageDbDao.getByInspectionIdAndZoneId(inspectionId, zone.id, hydrate)
                        .then(value => {
                            zone.damages = value;
                        }));
                });

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return zones;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Zone.TABLENAME + "_faceId"
                + " ON " + Zone.TABLENAME + "(faceId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Zone.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "faceId TEXT, "
                    + "shapeType TEXT, "
                    + "coordinates TEXT,"
                    + "zoneClick TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Zone.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Zone.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Zone> {
        throw new NotImplementedError();
    }

    public getTableName(): string {
        return Zone.TABLENAME;
    }

    protected rowToModel(row: any): Zone {
        let zone = new Zone();

        zone.id = row.id;
        zone.faceId = row.faceId;
        zone.shapeType = row.shapeType;
        zone.coordinates = row.coordinates;
        zone.zoneClick = row.zoneClick;

        return zone;
    }

    public save(zone: Zone): Promise<Zone> {
        let promises = [];

        zone.damages.forEach(damage => {
            promises.push(this.damageDbDao.save(damage));
        });

        return Promise.all(promises).then(value => {
            let query = "INSERT OR REPLACE INTO " + Zone.TABLENAME + " ("
                        + "id, faceId, shapeType, coordinates, zoneClick"
                        + ") VALUES ("
                        + this.getValue(zone.id)
                        + this.getValue(zone.faceId)
                        + this.getValue(zone.shapeType)
                        + this.getValue(zone.coordinates)
                        + this.getValue(zone.zoneClick, true)
                        + ");";

            return this.sqlProvider.query(query)
                .then(response => {
                    return zone;
                })
                .catch(reason => {
                    this.logSqlError(reason);
                    return null;
                });
        });
    }
}
