import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../../../gyzmo-commons/dao/db/base/db.dao.base";
import { NotImplementedError } from "../../../../../gyzmo-commons/helpers/notImplementedError";
import { AppSqlProvider } from "../../../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../../../gyzmo-commons/services/logs/logger.service";
import { Checklist } from "../../../../models/inspection/v2/checklist.model";
import { ChecklistElementDbDaoV2 } from "./checklistElement.db.dao";

@Injectable({
    providedIn: "root",
})
export class ChecklistDbDaoV2 extends DbDaoBase<Checklist> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider,
        private checklistElementDbDao: ChecklistElementDbDaoV2) {
        super(logger);
    }

    getList(ids: string[], inspectionId: string, hydrate: boolean = false): Promise<Checklist[]> {
        let selectQuery = "SELECT * FROM " + Checklist.TABLENAME + " WHERE id IN (";

        ids.forEach(id => {
            selectQuery += "'" + id + "',";
        });
        selectQuery = selectQuery.substring(0, selectQuery.length - 1);
        selectQuery += ") ORDER BY _order;";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let checklists: Checklist[] = [];
                for (const item of data.rows) {
                    let checklist = this.rowToModel(item);
                    checklists.push(checklist);
                }

                let hydratationPromises = [];

                if (hydrate) {
                    checklists.forEach(checklist => {
                        hydratationPromises.push(this.checklistElementDbDao.getByInspectionIdAndChecklistId(inspectionId, checklist.id, hydrate)
                            .then(value => {
                                checklist.elements = value;
                            }));
                    });
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return checklists;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Checklist.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT, "
                    + "_order TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Checklist.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Checklist.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Checklist> {
        throw new NotImplementedError();
    }

    public getTableName(): string {
        return Checklist.TABLENAME;
    }

    protected rowToModel(row: any): Checklist {
        let checklist = new Checklist();

        checklist.id = row.id;
        checklist.wording = row.wording;
        checklist.order = row._order;

        return checklist;
    }

    public save(checklist: Checklist): Promise<Checklist> {
        let promises = [];
        checklist.elements.forEach(element => {
            promises.push(this.checklistElementDbDao.save(element));
        });

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Checklist.TABLENAME + " (id, wording, _order) VALUES ("
                            + this.getValue(checklist.id)
                            + this.getValue(checklist.wording)
                            + this.getValue(checklist.order, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return checklist;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
