import { Component, forwardRef, Input, ViewChild } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { IonRippleEffect, ModalController } from "@ionic/angular";
import { TypeaheadSelectionModal } from "../../modals/typeahead-selection/typeahead-selection.modal";

@Component({
    selector: "irium-select",
    templateUrl: "./irium-select.component.html",
    styleUrls: ["./irium-select.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => IriumSelectComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => IriumSelectComponent),
        },
    ],
})
export class IriumSelectComponent implements ControlValueAccessor, Validator {
    @ViewChild("rippleEffect", { static: false }) rippleEffect: IonRippleEffect;

    @Input() possibleValues: { id: string, wording: string }[] = [];
    @Input() label: string = "";
    @Input() placeholder: string = "";
    @Input() mandatory: boolean = false;
    @Input() disabled: boolean = false;
    @Input() labelPlacement: "fixed" | "floating" | "stacked" = "stacked";
    @Input() line: boolean = true;
    @Input() justify: "start" | "end" | "space-between" = "start";

    value: string = null;
    touched = false;

    constructor(private modalController: ModalController) {
    }

    onChange = (value) => {
    };

    onTouched = () => {
    };

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    writeValue(value: any) {
        this.value = value;
    }

    markAsTouched() {
        this.onTouched();
        this.touched = true;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null;
    }

    public onSelect($event: any) {
        this.value = $event.detail.value;
        this.onChange(this.value);
        this.markAsTouched();
    }

    public async openModal($event) {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        $event.stopPropagation();

        let modal = await this.modalController.create({
            component: TypeaheadSelectionModal,
            componentProps: { items: this.possibleValues, title: this.placeholder },
            backdropDismiss: true,
        });

        void modal.onDidDismiss()
            .then(result => {
                if (result.data) {
                    this.value = result.data.item.id;
                    this.onChange(this.value);
                    this.markAsTouched();
                }
            });
        void modal.present();
    }

    public playRippleEffect($event: PointerEvent) {
        if (this.disabled) return;

        void this.rippleEffect.addRipple($event.clientX, $event.clientY)
            .then(removeRipple => {
                removeRipple();
            });
    }

    public getWording(value: string) {
        let find = this.possibleValues.find((element) => {
            return element.id == value;
        });
        return find ? find.wording : "";
    }
}
