import { Injectable } from "@angular/core";
import { AppUpdate, AppUpdateAvailability } from "@capawesome/capacitor-app-update";

@Injectable({
    providedIn: "root",
})
export class AppUpdateService {
    public async isUpdateAvailable() {
        const result = await AppUpdate.getAppUpdateInfo();
        return result.updateAvailability == AppUpdateAvailability.UPDATE_AVAILABLE;
    }

    public async performImmediateUpdate() {
        const result = await AppUpdate.getAppUpdateInfo();
        if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
            return;
        }

        if (result.immediateUpdateAllowed) {
            await AppUpdate.performImmediateUpdate();
        } else {
            await this.openAppStore();
        }
    }

    public async startFlexibleUpdate() {
        const result = await AppUpdate.getAppUpdateInfo();
        if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
            return;
        }
        if (result.flexibleUpdateAllowed) {
            await AppUpdate.startFlexibleUpdate();
        } else {
            await this.openAppStore();
        }
    }

    async completeFlexibleUpdate() {
        await AppUpdate.completeFlexibleUpdate();
    }

    private async openAppStore() {
        await AppUpdate.openAppStore();
    }
}
