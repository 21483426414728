import { Injectable } from "@angular/core";
import { App } from "@capacitor/app";
import { Version } from "../../environments/version";
import { VersionDto } from "../dtos/version.dto";
import { DeviceHelper } from "../helpers/device.helper";

@Injectable({
    providedIn: "root",
})
export class AppVersionService {
    constructor(public deviceHelper: DeviceHelper) {
    }

    public async getAppVersionName(): Promise<VersionDto> {
        if (this.deviceHelper.isRunningOnDevice()) {
            return new VersionDto(0, (await App.getInfo()).version);
        } else {
            return new VersionDto(0, Version.version);
        }
    }

    public async getAppVersionCode(): Promise<number> {
        if (this.deviceHelper.isRunningOnDevice()) {
            return Number((await App.getInfo()).build);
        } else {
            return Version.code;
        }
    }
}
