import { Injectable } from "@angular/core";
import { IAttachmentDto } from "src/gyzmo-commons/interfaces/IAttachmentDto";
import { AttachmentFactory } from "../../gyzmo-commons/interfaces/attachmentFactory";
import { AttachmentKinds } from "../../gyzmo-commons/interfaces/attachmentKinds";
import { IDocumentsProvider } from "../../gyzmo-commons/interfaces/IDocumentsProvider";
import { CacheService } from "../../gyzmo-commons/services/cache.service";
import { AttachmentWsDao } from "../dao/ws/attachment.ws.dao";
import { AttachmentDto } from "../dto/attachment.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

export class AppAttachmentFactory extends AttachmentFactory {
    createAttachment(): IAttachmentDto {
        return new AttachmentDto();
    }
}

@Injectable({
    providedIn: "root",
})
export class AttachmentService extends IDocumentsProvider {
    constructor(private attachmentWsDao: AttachmentWsDao,
                private cacheService: CacheService,
                private serversConnectionsProvider: ServersConnectionsProvider) {
        super();
    }

    public async cacheByCompanyId(companyId: string) {
        await this.attachmentWsDao.getByCompanyId(this.serversConnectionsProvider.getServerConnection(), companyId);
    }

    public getByDamageId(damageId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return this.attachmentWsDao.getByDamageId(this.serversConnectionsProvider.getPrimaryServerConnection(), damageId, attachmentKind);
    }

    public async getByObjectAndKey(object: string, key: string) {
        return this.attachmentWsDao.getByObjectAndKey(this.serversConnectionsProvider.getPrimaryServerConnection(), object, key);
    }

    public async getCGU(companyId: string): Promise<string> {
        let cacheId = "company/" + companyId + "/" + AttachmentKinds.CGU;
        if (await this.cacheService.isCached(cacheId)) {
            return (await this.cacheService.getCached(cacheId)).value;
        } else {
            return "";
        }
    }

    public async getCGL(companyId: string): Promise<string> {
        let cacheId = "company/" + companyId + "/" + AttachmentKinds.CGL;
        if (await this.cacheService.isCached(cacheId)) {
            return (await this.cacheService.getCached(cacheId)).value;
        } else {
            return "";
        }
    }

    public async getPrivacyPolicy(companyId: string): Promise<string> {
        let cacheId = "company/" + companyId + "/" + AttachmentKinds.POLITIQUE_CONFID;
        if (await this.cacheService.isCached(cacheId)) {
            return (await this.cacheService.getCached(cacheId)).value;
        } else {
            return "";
        }
    }

    public async getUserManual(): Promise<string> {
        let cacheId = "company/" + "IRIUM" + "/" + AttachmentKinds.MODE_EMPLOI;
        if (await this.cacheService.isCached(cacheId)) {
            return (await this.cacheService.getCached(cacheId)).value;
        } else {
            return "";
        }
    }
}
