import { Injectable } from "@angular/core";
import { IdWording } from "../../models/idWording.model";
import { AppSqlProvider } from "../../persistence/app.sql.provider";
import { LoggerService } from "../../services/logs/logger.service";
import { DbDaoBase } from "./base/db.dao.base";

@Injectable({
    providedIn: "root",
})
export class IdWordingDbDao extends DbDaoBase<IdWording> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + IdWording.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + IdWording.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + IdWording.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<IdWording> {
        let selectQuery = "SELECT * FROM " + IdWording.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return IdWording.TABLENAME;
    }

    protected rowToModel(row: any): IdWording {
        return IdWording.fromRow(row);
    }

    public save(kyWording: IdWording): Promise<IdWording> {
        let query = "INSERT OR REPLACE INTO " + IdWording.TABLENAME + " (id, wording) VALUES ("
                    + this.getValue(kyWording.id)
                    + this.getValue(kyWording.wording, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return kyWording;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
