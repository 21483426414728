import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: "about", loadChildren: () => import("../gyzmo-commons/pages/about/about.module").then(m => m.AboutPageModule),
    },
    {
        path: "cgu", loadChildren: () => import("../gyzmo-commons/pages/account/cgu/cgu.module").then(m => m.CguPageModule),
    },
    {
        path: "privacy-policy", loadChildren: () => import("../gyzmo-commons/pages/account/privacy-policy/privacy-policy.module").then(m => m.PrivacyPolicyPageModule),
    },
    {
        path: "connection", loadChildren: () => import("../gyzmo-commons/pages/account/connection/connection.module").then(m => m.ConnectionPageModule),
    },
    {
        path: "intermediate-search", loadChildren: () => import("./inspection/common/intermediate-search/intermediate-search.module").then(m => m.IntermediateSearchPageModule),
    },
    {
        path: "return-search", loadChildren: () => import("./inspection/common/return-search/return-search.module").then(m => m.ReturnSearchPageModule),
    },
    {
        path: "departure-search", loadChildren: () => import("./inspection/common/departure-search/departure-search.module").then(m => m.DepartureSearchPageModule),
    },
    {
        path: "movement-departure", loadChildren: () => import("./inspection/common/movement-departure/movement.module").then(m => m.MovementPageModule),
    },
    {
        path: "movement-return", loadChildren: () => import("./inspection/common/movement-return/movement.module").then(m => m.MovementPageModule),
    },
    {
        path: "mileage-entry", loadChildren: () => import("./inspection/common/mileage-entry/mileage-entry.module").then(m => m.MileageEntryPageModule),
    },
    {
        path: "inspection-list-v2", loadChildren: () => import("./inspection/V2/inspection-list/inspection-list.module").then(m => m.InspectionListPageModule),
    },
    {
        path: "inspection-list-v2/:seed", loadChildren: () => import("./inspection/V2/inspection-list/inspection-list.module").then(m => m.InspectionListPageModule),
    },
    {
        path: "checklist-v2", loadChildren: () => import("./inspection/V2/checklist/checklist.module").then(m => m.ChecklistPageModule),
    },
    {
        path: "face-v2", loadChildren: () => import("./inspection/V2/face/face.module").then(m => m.FacePageModule),
    },
    {
        path: "face-v2/:iterator", loadChildren: () => import("./inspection/V2/face/face.module").then(m => m.FacePageModule),
    },
    {
        path: "face-general-photo-v2", loadChildren: () => import("./inspection/V2/face-general-photo/face-general-photo.module").then(m => m.FaceGeneralPhotoPageModule),
    },
    {
        path: "inspection-summary-v2", loadChildren: () => import("./inspection/V2/inspection-summary/inspection-summary.module").then(m => m.InspectionSummaryPageModule),
    },
    {
        path: "inspection-list-list", loadChildren: () => import("./inspection/V2/inspection-list-list/inspection-list-list.module").then(m => m.InspectionListListPageModule),
    },
    {
        path: "equipment-tyres-wear",
        loadChildren: () => import("./inspection/V2/equipment-tyres-wear/equipment-tyres-wear.module").then(m => m.EquipmentTyresWearPageModule),
    },
    {
        path: "equipment-tyres-definition",
        loadChildren: () => import("./inspection/V2/equipment-tires-definition/equipment-tires-definition.module").then(m => m.EquipmentTiresDefinitionPageModule),
    },
    {
        path: "reinitialize-password",
        loadChildren: () => import("../gyzmo-commons/pages/account/password/reinitialize-password/reinitialize-password.module").then(m => m.ReinitializePasswordModule),
    },
    {
        path: "change-password",
        loadChildren: () => import("../gyzmo-commons/pages/account/password/change-password/change-password.module").then(m => m.ChangePasswordModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: "reload",
            initialNavigation: "disabled",
            scrollPositionRestoration: "enabled",
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
