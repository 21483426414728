import { DeviceHelper } from "../helpers/device.helper";
import { LANGUAGES } from "../helpers/i18n/constants";
import { isNullOrEmpty } from "../helpers/null.helper";
import { AppVersionService } from "../services/appVersion.service";
import { LanguageService } from "../services/language.service";
import { AC_ALLOWORIGIN, ACCEPT, ACCEPTLANGUAGE, AUTHORIZATION, CONTENTTYPE, XGYZMOAPIKEY, XVEGAAPIKEY, XVEGAAPP, XVEGAAPPVERSION, XVEGAAUTH, XVEGACONTEXT, XVEGASERIAL, XVEGATOKEN, XVEGAVERSION } from "./header.constant";

export class HttpHeadersProvider {
    private _headers = {};

    constructor(module: string,
                apiVersion: number,
                private deviceHelper: DeviceHelper,
                private appVersionService: AppVersionService,
                languageService: LanguageService) {
        this.initializeHeaders(module, apiVersion);
        this.setLanguage(languageService.getUserLanguage());
    }

    get Headers(): {} {
        return this._headers;
    }

    public setAuthentication(token: string) {
        delete this._headers[XVEGATOKEN];
        delete this._headers[AUTHORIZATION];

        if (!isNullOrEmpty(token)) {
            this._headers[XVEGATOKEN] = token;
        }

        this._headers[XVEGASERIAL] = this.deviceHelper.getDeviceSerial();
    }

    public setApiKey(keyValue: string) {
        this._headers[XVEGAAPIKEY] = keyValue;
        this._headers[XGYZMOAPIKEY] = keyValue;
    }

    public setAppVersion(keyValue: string) {
        this._headers[XVEGAAPIKEY] = keyValue;
        this._headers[XGYZMOAPIKEY] = keyValue;
    }

    public setContext(keyValue: string) {
        if (keyValue != "") {
            this._headers[XVEGACONTEXT] = keyValue;
        }
    }

    public setLanguage(language: LANGUAGES) {
        switch (language) {
            case LANGUAGES.FRENCH:
                this._headers[ACCEPTLANGUAGE] = "001";
                break;
            case LANGUAGES.ENGLISH:
                this._headers[ACCEPTLANGUAGE] = "002";
                break;
            case LANGUAGES.SPANISH:
                this._headers[ACCEPTLANGUAGE] = "003";
                break;
            case LANGUAGES.GERMAN:
                this._headers[ACCEPTLANGUAGE] = "DE";
                break;
            case LANGUAGES.DUTCH:
                this._headers[ACCEPTLANGUAGE] = "NL";
                break;
            case LANGUAGES.PORTUGUESE:
                this._headers[ACCEPTLANGUAGE] = "PT";
                break;
            default:
                this._headers[ACCEPTLANGUAGE] = "002";
                break;
        }
    }

    public getApiVersion(): number {
        return Number(this._headers[XVEGAVERSION]);
    }

    public setApiVersion(version: number) {
        this._headers[XVEGAVERSION] = "" + version;
    }

    private initializeHeaders(module: string, apiVersion: number) {
        this._headers[AC_ALLOWORIGIN] = "*";
        this._headers[CONTENTTYPE] = "application/json";
        this._headers[ACCEPT] = "application/json";

        this._headers[XVEGAVERSION] = "" + apiVersion;
        this._headers[XVEGASERIAL] = "no-serial";
        this._headers[XVEGAAUTH] = "locpro";

        this.appVersionService.getAppVersionName()
            .then(value => {
                this._headers[XVEGAAPPVERSION] = value.version;
            });

        this.setModule(module);
    }

    private setModule(module: string) {
        this._headers[XVEGAAPP] = module;
    }
}
