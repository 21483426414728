import { Injectable } from "@angular/core";
import { IServerDto } from "src/gyzmo-commons/interfaces/IServerDto";
import { KeyValueDbDao } from "../../gyzmo-commons/dao/db/keyValue.db.dao";
import { DeviceHelper } from "../../gyzmo-commons/helpers/device.helper";
import { NetworkHelper } from "../../gyzmo-commons/helpers/network.helper";
import { IServerDefaultConnectionProvider } from "../../gyzmo-commons/interfaces/IServerDefaultConnectionProvider";
import { KeyValue } from "../../gyzmo-commons/models/keyValue.model";
import { HttpClientProvider } from "../../gyzmo-commons/providers/httpClientProvider";
import { AppVersionService } from "../../gyzmo-commons/services/appVersion.service";
import { LanguageService } from "../../gyzmo-commons/services/language.service";
import { ServerDbDao } from "../dao/db/server.db.dao";
import { ServerDto } from "../dto/server.dto";
import { HttpErrorHandler } from "../http/httpErrorHandler";
import { ServerConnection } from "../http/serverConnection";
import { OfflineModeService } from "../services/offlineMode.service";

export const CURRENT_SERVER_CONFIGURATION: string = "currentServerConfiguration";

@Injectable({
    providedIn: "root",
})
export class ServersConnectionsProvider extends IServerDefaultConnectionProvider {
    protected serversConnection: ServerConnection = null;

    constructor(private deviceHelper: DeviceHelper,
                private appVersionService: AppVersionService,
                private languageService: LanguageService,
                private networkHelper: NetworkHelper,
                private httpClientProvider: HttpClientProvider,
                private httpErrorHandler: HttpErrorHandler,
                private serverDbDao: ServerDbDao,
                private keyValueDbDao: KeyValueDbDao,
                private offlineModeService: OfflineModeService) {
        super();
    }

    createServerDto(): IServerDto {
        return new ServerDto();
    }

    public async setServerConnection(url: string, apikey: string, context: string) {
        let serverDto = new ServerDto();
        serverDto.url = url;
        serverDto.apiKey = apikey;
        serverDto.context = context;

        await this.keyValueDbDao.save(new KeyValue(CURRENT_SERVER_CONFIGURATION, JSON.stringify(serverDto)));

        await this.serverDbDao.deleteAll();
        await this.serverDbDao.save(serverDto.toModel());
        await this.initialize();

        return serverDto;
    }

    public getServerConnection(): ServerConnection {
        return this.serversConnection;
    }

    public getPrimaryServerConnection(): ServerConnection {
        return this.getServerConnection();
    }

    public async initialize(): Promise<void> {
        let servers = await this.serverDbDao.list();

        if (servers.length > 0) {
            this.serversConnection = new ServerConnection(
                this.deviceHelper,
                this.languageService,
                this.appVersionService,
                this.networkHelper,
                ServerDto.fromModel(servers[0]),
                this.serverDbDao,
                this.httpClientProvider,
                this.httpErrorHandler,
                this.offlineModeService);
        }
    }
}
